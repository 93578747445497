import React from "react";
import "../styles/App.css";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { Button } from "../components/Button";

export default function LeeSummit() {
  return (
    <>
      <NavBar
        title="Kansas City Moving | Point Blank Moving"
        description="Point Blank moving is now located in Lee's Summit Movers and Overland Park Movers. Point Blank Moving is Kansas City's premier, five star moving company! Proud to be servicing out of Lee's Summit and Overland Park and any other KC area."
      />
      <div className="locations-container">
        <h1 style={{ textShadow: "0 0 5px black, 0 0 5px black" }}>
          Locations
        </h1>
        <div className="landing-btns">
          <Button
            className="btns"
            buttonStyle="btn--primary"
            buttonSize="btn--extralarge"
            path="/Overland-park-movers"
          >
            Overland Park
          </Button>
          <Button
            className="btns"
            buttonStyle="btn--outline"
            buttonSize="btn--extralarge"
            path="/Lee-summit-movers"
          >
            Lee's Summit
          </Button>
        </div>
      </div>
      <Footer />
    </>
  );
}
